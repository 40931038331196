<template>
  <div class="body22">
    <div class="back">
      <div class="logo"><img class="large" :src="$tenant.bigLogo" alt="" style="width: 227px; height: 34px"/></div>
      <div class="content">
        <p style="font-size: 12px;color: #404040;margin: 10px 0 10px 10px">{{$t("maintWorkOrder.currentLocation")}}</p>
        <div class="vm-separate" style="border: solid 1px #D63B3F;background-color: #FBEBEB;">
          <div style="margin-left:10px;margin-right:10px;line-height: 50px">
            <span
              style="font-size: 20px;font-weight: 600;color: #333333">{{$t("maintWorkOrder.orderNum")}}：{{maintWorkOrder.orderNum}}
            </span>
          </div>
          <div style="line-height: 50px">
            <span style="font-size: 20px;font-weight: 600;color: #333333"> {{$t("maintWorkOrder.maintStatus")}}：
              <span v-if="maintWorkOrder.maintStatus === 0" style="color: #333333">
                {{$t("maintWorkOrder.invalid")}}
              </span>
              <span v-if="maintWorkOrder.maintStatus === 1" style="color: #333333">
                {{$t("maintWorkOrder.inPlan")}}
              </span>
              <span v-if="maintWorkOrder.maintStatus === 2" style="color: #333333">
                {{$t("maintWorkOrder.notAccepted")}}
              </span>
              <span v-if="maintWorkOrder.maintStatus === 3" style="color: #333333">
                {{$t("maintWorkOrder.accepted")}}
              </span>
              <span v-if="maintWorkOrder.maintStatus === 4" style="color: #333333">
                {{$t("maintWorkOrder.signedIn")}}
              </span>
              <span v-if="maintWorkOrder.maintStatus === 5" style="color: #333333">
                {{$t("maintWorkOrder.completed")}}
              </span>
            </span>
          </div>
          <div style="line-height: 50px">
            <span style="font-size: 20px;font-weight: 600;color: #333333"> {{$t("maintWorkOrder.completionStatus")}}：
              <span v-if="maintWorkOrder.completionStatus === 1" style="color: #333333">
                {{$t("maintWorkOrder.normal")}}
              </span>
              <span v-if="maintWorkOrder.completionStatus === 2" style="color: #333333">
                {{$t("maintWorkOrder.advance")}}
              </span>
              <span v-if="maintWorkOrder.completionStatus === 3" style="color: #333333">
                {{$t("maintWorkOrder.overdue")}}
              </span>
            </span>
          </div>
        </div>
        <el-row>
          <ul class="vm-separate" style="margin: 20px 0;">
            <li>
              <span v-if="maintWorkOrder.maintStatus !== 0">
                <p class="title">{{$t("maintWorkOrder.created")}}</p>
                <p><img src="/static/images/workOrder/yi_bao_jing.png" alt=""/></p>
                <p class="date">{{maintWorkOrder.createTime}}</p>
              </span>
              <span v-else>
                <p class="title">{{$t("maintWorkOrder.invalid")}}</p>
                <p><img src="/static/images/workOrder/yi_jie_jing.png" alt=""/></p>
              </span>
            </li>
            <li>
              <span v-if="maintWorkOrder.maintStatus > 1">
                <p class="title">{{$t("maintWorkOrder.send")}}</p>
                <p><img src="/static/images/workOrder/yi_dao_chang.png" alt=""/></p>
              </span>
              <span v-else>
                <p class="title">{{$t("maintWorkOrder.notSend")}}</p>
                <p><img src="/static/images/workOrder/daochang_b.png" alt=""/></p>
              </span>
            </li>
            <li>
              <span v-if="maintWorkOrder.maintStatus > 2">
                <p class="title">{{$t("maintWorkOrder.accepted")}}</p>
                <p><img src="/static/images/workOrder/yi_jie_jing.png" alt=""/></p>
                <p class="date">{{maintWorkOrder.acceptanceTime}}</p>
              </span>
              <span v-else>
                <p class="title">{{$t("maintWorkOrder.notAccepted")}}</p>
                <p><img src="/static/images/workOrder/jiejing_b.png" alt=""/></p>
              </span>
            </li>
            <li>
              <span v-if="maintWorkOrder.maintStatus > 3">
                <p class="title">{{$t("maintWorkOrder.signedIn")}}</p>
                <p><img src="/static/images/workOrder/yi_wan_gong.png" alt=""/></p>
                <p class="date">{{maintWorkOrder.checkinTime}}</p>
              </span>
              <span v-else>
                <p class="title">{{$t("maintWorkOrder.notSignIn")}}</p>
                <p><img src="/static/images/workOrder/wangong_b.png" alt=""/></p>
              </span>
            </li>
            <li>
              <span v-if="maintWorkOrder.maintStatus > 4">
                <p class="title">{{$t("common.completed")}}</p>
                <p><img src="/static/images/workOrder/yi_que_ren.png" alt=""/></p>
                <p class="date">{{maintWorkOrder.completionTime}}</p>
              </span>
              <span v-else>
                <p class="title">{{$t("common.notCompleted")}}</p>
                <p><img src="/static/images/workOrder/queren_b.png" alt=""/></p>
              </span>
            </li>
          </ul>
        </el-row>
        <div style="border: solid 1px #DCDFE6;">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span style="margin: 10px;line-height: 30px;font-weight: 600">{{$t("maintWorkOrder.maintBaseInfo")}}</span>
          </div>
          <div style="margin: 0 20px 0 20px">
            <div style="margin: 10px;">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{$t("maintWorkOrder.maintWorkOrder")}}</span>
              <div class="vm-separate">
                <span style="margin-top: 5px">{{$t("maintWorkOrder.creator")}}：{{maintWorkOrder.creator}}</span>
                <span>{{$t("maintWorkOrder.maintDate")}}：{{maintWorkOrder.maintDate}}</span>
              </div>
              <div class="vm-separate">
                <span>{{$t("maintWorkOrder.maintainerName1")}}：{{maintWorkOrder.maintainerName1}}</span>
                <span>{{$t("maintWorkOrder.maintainerName2")}}：{{maintWorkOrder.maintainerName2}}</span>
              </div>
              <div class="vm-separate">
                <span>{{$t("maintWorkOrder.maintTypeName")}}：{{maintWorkOrder.maintTypeName}}</span>
                <span>{{$t("common.remark")}}：{{maintWorkOrder.remark}}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="border: solid 1px #DCDFE6;margin-top: 20px">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;display: flex;justify-content: space-between">
            <span
              style="margin: 5px 10px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display2 = changeDisplay(display2)">{{$t("maintWorkOrder.maintItem")}}
              <i v-if="display2 === 'block'" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </span>
          </div>
          <div :style="{display: display2}">
            <maint-item-detail :id="$route.params.maintWorkOrderId"></maint-item-detail>
          </div>
        </div>
        <div v-if="maintWorkOrder.maintStatus !== 5 && maintDevice.length > 0" style="border: solid 1px #DCDFE6;margin-top: 20px;">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span
              style="margin: 10px;line-height: 30px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display4 = changeDisplay(display4)">
              {{$t("maintWorkOrder.maintDevice")}}
              <i v-if="display4 === 'block'" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </span>
          </div>
          <div :style="{display: display4}">
            <el-table
              :data="maintDevice"
              :cell-style="{'border': 'none'}"
              :row-class-name="tableRowClassName"
              border
              :default-expand-all="true">
              <el-table-column type="expand">
                <template v-slot="scope">
                  <table class="childTable">
                    <tr>
                      <th style="width: 7%">{{$t("common.index")}}</th>
                      <th>{{$t("maintWorkOrder.maintDevice")}}</th>
                    </tr>
                    <tr v-for="(device, index) in scope.row.faultMaintRecos" :key="index">
                      <td style="text-align: center">{{index + 1}}</td>
                      <td>{{device.maintReco}}</td>
                    </tr>
                  </table>
                </template>
              </el-table-column>
              <el-table-column prop="faultDesc" :label="$t('elevator.potentialFault')"></el-table-column>
            </el-table>
          </div>
        </div>
        <div v-if="maintWorkOrder.maintLifeCycles.length > 0" style="border: solid 1px #DCDFE6;margin-top: 20px;">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span
              style="margin: 10px;line-height: 30px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display1 = changeDisplay(display1)">
              {{$t("maintWorkOrder.treatmentProcess")}}
              <i v-if="display1 === 'block'" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </span>
          </div>
          <div :style="{display: display1}" style="max-height: 450px;overflow-y: scroll">
            <el-table
              :data="maintWorkOrder.maintLifeCycles"
              :cell-style="{'border': 'none'}"
              :row-class-name="tableRowClassName"
              border>
              <el-table-column :label="$t('mb.eventName')">
                <template v-slot="scope">
                  <i v-if="!scope.row.event" class="el-icon-user"></i>
                  <span v-if="scope.row.record !== '系统自动创建工单'">{{scope.row.creator}}</span>
                  <span v-if="scope.row.record.includes('image')">
                    {{" " + scope.row.record.substring(0, scope.row.record.indexOf("http"))}}
                    <el-image
                      style="max-width:80px;height:auto"
                      :src="scope.row.record.substring(scope.row.record.indexOf('http'), scope.row.record.length)"
                      fit="contain"
                      lazy
                      :preview-src-list="[scope.row.record.substring(scope.row.record.indexOf('http'), scope.row.record.length)]">
                    </el-image>
                  </span>
                  <span v-else-if="scope.row.creatorId === -1">
                    {{$t("maintWorkOrder.uploadPicture")}}
                    <el-image
                      style="max-width:50px;height:auto"
                      :src="handleOldPhoto(scope.row.record)"
                      fit="contain"
                      lazy
                      :preview-src-list="[handleOldPhoto(scope.row.record)]">
                    </el-image>
                  </span>
                  <span v-else-if="scope.row.record.includes('file')">
                    {{" " + scope.row.record.substring(0, scope.row.record.indexOf("http"))}}
                    <audio
                      style="width: 30%; height: 20px"
                      :src="scope.row.record.substring(scope.row.record.indexOf('http'), scope.row.record.length)"
                      controls="controls">
                    </audio>
                  </span>
                  <span v-else style="white-space: pre-wrap">{{" " + scope.row.record}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" :label="$t('workOrder.createTime')" width="500"></el-table-column>
            </el-table>
          </div>
        </div>
        <div v-if="maintWorkOrder.maintStatus === 5" style="border: solid 1px #DCDFE6;margin-top: 20px;">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span
              style="margin: 10px;line-height: 30px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display3 = changeDisplay(display3)">
              {{$t("maintWorkOrder.sigAndReturnVisit")}}
              <i v-if="display3 === 'block'" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </span>
          </div>
          <div :style="{display: display3}" style="margin: 10px">
            <el-form :model="maintWorkOrder" label-width="140px">
              <div class="vm-separate">
                <el-form-item :label="$t('maintWorkOrder.evaluation')" :label-width="this.$l('270px','140px')">
                  <el-radio-group v-model="maintWorkOrder.satisfaction" style="margin-top: 5px" :disabled="true">
                    <el-radio :label="1">{{$t("maintWorkOrder.dissatisfied")}}</el-radio>
                    <el-radio :label="2">{{$t("maintWorkOrder.commonly")}}</el-radio>
                    <el-radio :label="3">{{$t("maintWorkOrder.satisfied")}}</el-radio>
                    <el-radio :label="4">{{$t("maintWorkOrder.verySatisfied")}}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item :label="$t('maintWorkOrder.customerSignature')">
                  <el-image
                    v-if="maintWorkOrder.customerSign"
                    style="max-width:80px;height:auto"
                    :src="maintWorkOrder.customerSign"
                    fit="contain"
                    lazy
                    :preview-src-list="[maintWorkOrder.customerSign]">
                  </el-image>
                </el-form-item>
              </div>
              <el-form-item :label="$t('maintWorkOrder.advice')">
                <el-input
                  v-if="maintWorkOrder.advice"
                  v-model="maintWorkOrder.advice"
                  style="width: 70%"
                  type="textarea"
                  :readonly="true">
                </el-input>
              </el-form-item>
              <el-form-item :label="$t('maintWorkOrder.returnRecord')">
                <el-input
                  v-model="maintWorkOrder.returnVisitRecord"
                  style="width: 70%"
                  type="textarea"
                  :rows="5">
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">{{$t("common.save")}}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
    </div>
  </div>
</template>

<script>
  const moduleName = "maintenanceOrder";
  import MaintItemDetail from "@/views/newMaintWorkOrder/MaintItemDetailList";

  export default {
    components: {MaintItemDetail},
    data() {
      return {
        copyright: window.config.copyright,
        display1: "block",
        display2: "block",
        display3: "block",
        display4: "block",
        maintWorkOrder: {
          id: 0,
          maintStatus: "",
          maintLifeCycles: [],
          satisfaction: 0,
          advice: "",
          customerSign: "",
          returnVisitRecord: "",
        },
        maintDevice: [],
      };
    },
    mounted() {
      this.maintWorkOrder.id = this.$route.params.maintWorkOrderId;
      this.getData();
      this.getMaintDevice();
    },
    methods: {
      getData() {
        this.$api.getById(moduleName, this.maintWorkOrder.id).then(res => {
          this.maintWorkOrder = res.data;
          this.getMkEvent();
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getMkEvent() {
        let param = {
          elevatorId: this.maintWorkOrder.elevatorId,
          startTime: this.maintWorkOrder.createTime,
          endTime: this.maintWorkOrder.completionTime,
        };
        this.$http.get(moduleName + "/mkEvent", param).then(res => {
          res.data.forEach(item => {
            let lifeCycle = {
              record: item.eventName,
              createTime: item.eventTime,
              event: true,
            };
            this.maintWorkOrder.maintLifeCycles.push(lifeCycle);
            this.maintWorkOrder.maintLifeCycles.sort((a, b) => {
              let aTime = Date.parse(new Date(a.createTime).toString());
              let bTime = Date.parse(new Date(b.createTime).toString());
              return aTime - bTime;
            });
          });
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getMaintDevice() {
        this.$api.getById(moduleName + "/device", this.maintWorkOrder.id).then(res => {
          this.maintDevice = res.data;
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      changeDisplay(e) {
        if (e === "block") {
          e = "none";
        } else {
          e = "block";
        }
        return e;
      },
      onSubmit() {
        this.$http.put(moduleName, this.maintWorkOrder).then(() => {
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.saveError"));
          }
        });
      },
      handleOldPhoto(record) {
        return window.config.SERVER_URL + record;
      },
      tableRowClassName({ row, rowIndex }) {
        if (rowIndex % 2 === 1) {
          return "highting-row";
        }
      }
    },
  };
</script>
<style lang="scss" scoped>

.body22 {
  background-color: #404040;
  height: 100%;
  overflow-y:scroll;
  .back {
    width: 100%;
    .logo {
      width: 1090px;
      background-color: #404040;
      margin: 0 auto;
      padding: 10px 0;
    }
    .content {
      width: 1090px;
      background-color: white;
      margin:0 auto;
      border: solid 1px #DCDFE6;
      padding-bottom: 50px;
    }
  }
  .footer {
    color: #FFF;
    background-color: #404040;
    position: fixed;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 100;
  }
}

span {
  color: #606266;
}

.vm-separate {
  font-size: 12px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

ul {
  white-space: nowrap;
  width: 92%;
}

li {
  list-style-type: none;
  display: inline;
}

p {
  margin: 0;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #5a9d11;
  margin-left: -10px;
}

.date {
  font-size: 12px;
  color: #666666;
  text-align: center;
  margin-top: 5px;
}

.lifeCycle {
  width: 100%;
  border-collapse: collapse;
}
.lifeCycle td {
  border: none;
  padding: 10px;
  line-height: 30px;
  font-size: 12px;
  border-collapse: collapse;
  text-align: center;
}
.childTable {
  width: 100%;
  border-collapse: collapse;

  td {
    border: 1px solid #D8DCE5;
    padding: 5px 10px;
    background-color: #FAFAFA;
  }

  th {
    font-weight: 500;
    text-align: center;
    border: 1px solid #D8DCE5;
    padding: 8px 10px;
    background-color: #EEF2F7;
  }
}
</style>
